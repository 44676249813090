import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Footer from '../Footer';
import CookiePolicyDialog from '../CookiePolicyDialog';

const Layout = ({
  children,
  className,
}) => (
  <div
    className={classNames(
      'PageWrapper',
      className,
    )}
  >
    {children}
    <Footer />
    <CookiePolicyDialog />
  </div>
);

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  className: PropTypes.string,
};

Layout.defaultProps = {
  className: undefined,
};

export default Layout;

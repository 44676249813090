/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import LazyImage from '../LazyImage';
import usFlag from '../../img/us-icon.svg';
import caFlag from '../../img/ca-icon.svg';

const FooterNavigation = () => (
  <div className="Footer__navigation">
    <div className="Footer__menu Footer__menu--contacts">
      <p className="Footer__menu__heading">Contacts</p>
      <ul className="Footer__menu__list list--clean">
        <li className="Footer__menu--contacts-phone">
          <a href="tel:+1-855-332-6213">1-855-332-6213</a> / <a href="tel:+44-808-164-0705">44-808-164-0705</a>
        </li>
        <li className="Footer__menu--contacts-chat">
          <a
            rel="nofollow noopener noreferrer"
            href="//m.me/1766981520097661"
            data-messenger=""
            target="_blank"
          >
            Facebook messenger
          </a>
        </li>
        <li className="Footer__menu--contacts-email">
          <a href="mailto:info@advancedwriters.com">info@advancedwriters.com</a>
        </li>
      </ul>
    </div>

    <div className="Footer__menu Footer__menu--terms">
      <p className="Footer__menu__heading">Terms</p>
      <ul className="Footer__menu__list list--clean">
        <li><a href="https://www.advancedwriters.com/privacy-policy.html" rel="nofollow">Privacy policy</a></li>
        <li><a href="https://www.advancedwriters.com/progressive-delivery.html" rel="nofollow">Progressive Delivery</a></li>
        <li><a href="https://www.advancedwriters.com/terms-conditions.html" rel="nofollow">Terms and conditions</a></li>
        <li><a href="https://www.advancedwriters.com/cookie-policy.html" rel="nofollow">Cookie policy</a></li>
        <li><a href="https://www.advancedwriters.com/plagiarism-free-guarantee.html" rel="nofollow">Plagiarism-free guarantee</a></li>
        <li><a href="https://www.advancedwriters.com/money-back-guarantee.html" rel="nofollow">Money back guarantee</a></li>
        <li><a href="https://www.advancedwriters.com/revisions-policy.html" rel="nofollow">Revision policy</a></li>
        <li><a href="https://www.advancedwriters.com/social-responsibility-notice.html" rel="nofollow">Notice on social responsibility</a></li>
      </ul>
    </div>

    <div className="Footer__menu Footer__menu--accept">
      <p className="Footer__menu__heading Footer__menu__heading-accept">We accept</p>
      <div className="Footer__menu__payments">
        <LazyImage
          src="/icons/ico_payments.svg"
          width="229"
          height="35"
          alt=""
        />
      </div>
      <p className="Footer__menu__heading Footer__menu__heading-language">Language</p>
      <div className="Footer__menu__language">
        <ul className="Footer__menu__language__list list--clean">
          <li className="Footer__menu__language__list__item">
            <a className="Footer__menu__language__list__item__img" href="https://www.advancedwriters.com/">
              <LazyImage
                src={usFlag}
                alt="AdvancedWriters.com"
                title="AdvancedWriters.com"
                width="35"
                height="24"
              />
            </a>
          </li>
          <li className="Footer__menu__language__list__item">
            <a className="Footer__menu__language__list__img" href="https://ca.advancedwriters.com/">
              <LazyImage
                src={caFlag}
                alt="CA.AdvancedWriters.com"
                title="CA.AdvancedWriters.com"
                width="35"
                height="24"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default FooterNavigation;

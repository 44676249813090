// @todo enable next eslint rules
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import logo_father from '../../img/logo_feather.svg';
import logo_text from '../../img/logo_text.svg';

import './_index.scss';

class Header extends Component {
  state = {
    openMenu: false,
    serviceMenu: {},
    scroll: false,
  };

  componentDidMount() {
    window.onscroll = () => this.handleScroll();
  }

  handleScroll = () => {
    this.setState({
      scroll: window.scrollY > 0,
    });
  };

  toggleMenu = () => {
    this.setState(
      ({ openMenu }) => ({ openMenu: !openMenu }),
      () => {
        const { openMenu } = this.state;
        if (openMenu) {
          document.getElementsByTagName('html')[0].classList.add('Overflow');
          document.getElementsByTagName('body')[0].classList.add('Overflow');
        } else {
          document.getElementsByTagName('html')[0].classList.remove('Overflow');
          document.getElementsByTagName('body')[0].classList.remove('Overflow');
        }
      },
    );
  };

  serviceToggleMenu = (el) => {
    el.persist();
    const id = el.target.id;
    const current = this.state.serviceMenu[id] ? this.state.serviceMenu[id] : false;

    this.setState(({ serviceMenu }) => ({
      serviceMenu: {
        ...serviceMenu,
        [id]: !current && window.innerWidth <= 1023,
      },
    }));
  };

  render() {
    const { openMenu, serviceMenu, scroll } = this.state;
    const { children } = this.props;

    const awSiteUrl = 'https://www.advancedwriters.com';

    return (
      <header className={classNames('Header', openMenu && 'Header-nav--open', scroll && 'scroll')}>
        <div className="container">
          <div className="Header__container">
            <a className="Header__logo" href="/">
              <img
                className="Header__logo__feather"
                src={logo_father}
                width="41"
                height="57"
                alt="logo feather"
                title=""
              />
              <img
                className="Header__logo__text"
                src={logo_text}
                width="236"
                height="36"
                alt="logo text"
                title=""
              />
            </a>
            <div className="Header__user-box">
              <a
                href={`${awSiteUrl}/dashboard/authorization`}
                rel="nofollow"
                className="Header__user-box__link-dashboard"
              >
                Manage orders
              </a>
              <a
                className="btn btn--white-unfill btn--small Header__user-box__link-order"
                rel="nofollow"
                href={`${awSiteUrl}/dashboard/orders/create/`}
              >
                New order
              </a>
            </div>
            <button
              className={classNames('Header__burger', openMenu && 'Header__burger--active')}
              onClick={() => this.toggleMenu()}
              type="button"
              aria-label="toggle menu"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
            <nav className="Header__menu">
              <ul className="Header__menu-list list--clean">
                <li className="Header__menu-list-item">
                  <a href={`${awSiteUrl}/pricing.html`}>Pricing</a>
                </li>
                <li className="Header__menu-list-item">
                  <a href={`${awSiteUrl}/reviews.html`}>Reviews</a>
                </li>
                <li className="Header__menu-list-item">
                  <a href={`${awSiteUrl}/our-writers.html`}>Our writers</a>
                </li>
                <li className="Header__menu-list-item Header__submenu-container">
                  <span
                    id="services"
                    className={classNames('Header__submenu-label', serviceMenu.services && 'open')}
                    onClick={(e) => this.serviceToggleMenu(e)}
                  >
                    Services
                  </span>
                  <ul
                    className={classNames(
                      'Header__submenu list--clean',
                      serviceMenu.services && 'open',
                    )}
                  >
                    <li className="Header__menu-list-item">
                      <a href="/buy-essay-online.html">Buy essay online</a>
                    </li>
                    <li className="Header__menu-list-item">
                      <a href="/research-paper-writing-service.html">
                        Research paper writing service
                      </a>
                    </li>
                    <li className="Header__menu-list-item">
                      <a href="/write-my-essay.html">Write my essay</a>
                    </li>
                  </ul>
                </li>
                <li className="Header__menu-list-item Header__submenu-container">
                  <span
                    id="more"
                    className={classNames('Header__submenu-label', serviceMenu.more && 'open')}
                    onClick={(e) => this.serviceToggleMenu(e)}
                  >
                    More
                  </span>
                  <ul
                    className={classNames(
                      'Header__submenu list--clean',
                      serviceMenu.more && 'open',
                    )}
                  >
                    <li className="Header__menu-list-item">
                      <a href={`${awSiteUrl}/about-us.html`} rel="nofollow">
                        About us
                      </a>
                    </li>
                    <li className="Header__menu-list-item">
                      <a href={`${awSiteUrl}/contact-us.html`} rel="nofollow">
                        Contact us
                      </a>
                    </li>
                    <li className="Header__menu-list-item">
                      <a href={`${awSiteUrl}/howitworks.html`} rel="nofollow">
                        How it works
                      </a>
                    </li>
                    <li className="Header__menu-list-item">
                      <a href={`${awSiteUrl}/frequently-asked-questions.html`}>FAQ</a>
                    </li>
                    <li className="Header__menu-list-item">
                      <a href={`${awSiteUrl}/our-services.html`}>Our services</a>
                    </li>
                    <li className="Header__menu-list-item">
                      <a href={`${awSiteUrl}/blog/`}>Blog</a>
                    </li>
                  </ul>
                </li>
                <li className="Header__menu-list-item Header__menu-order-block">
                  <div className="Header__menu-order-block__phone">
                    <a href="tel:+1-855-332-6213">
                      <span className="hidden-info" data-title="1-855-332-6213"></span>
                    </a>
                  </div>
                  <a
                    className="btn btn--green-fill btn--with-arrow Header__menu-order-block__link"
                    rel="nofollow"
                    href={`${awSiteUrl}/dashboard/orders/create/`}
                  >
                    Order now
                  </a>
                </li>
              </ul>
            </nav>
            {children}
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  children: PropTypes.node,
};

Header.defaultProps = {
  children: undefined,
};

export default Header;

/* eslint-enable */

import React from 'react';
import FooterNavigation from './FooterNavigation';
import FooterBottom from './FooterBottom';

import './index.scss';

const Footer = () => (
  <footer className="Footer" role="contentinfo">
    <div className="container">
      <FooterNavigation />
      <div className="Footer__separator" />
      <FooterBottom />
    </div>
  </footer>
);

export default Footer;

// @todo enable next eslint rules
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import LazyImage from '../LazyImage';

const FooterBottom = () => (
  <div className="Footer__bottom">
    <div className="Footer__menu Footer__menu--disclaimer">
      <p className="Footer__menu__heading">Disclaimer</p>
      <div className="Footer__menu__text">
        <p><span className="hidden-info" data-title="Please note that all kinds of custom written papers ordered from AdvancedWriters.com academic writing service, including, but not limited to, essays, research papers, dissertations, book reviews, should be used as reference material only. Therefore, when citing a paper you get from us in your own work, it should be properly referenced." /></p>
      </div>
    </div>

    <div className="Footer__menu__copyright">
      <p>
        Copyright &copy; 2008&mdash;
        {new Date().getFullYear()}.
        {' '}
        AdvancedWriters.com. All rights reserved.
        <span className="dcma">
          <a
            rel="nofollow"
            href="//www.dmca.com/Protection/Status.aspx?ID=5730b24c-062a-4b2e-a53b-55028cf91f0f"
            title="DMCA.com Protection Status"
            className="dmca-badge"
          >
            <LazyImage
              src="//images.dmca.com/Badges/dmca_protected_sml_120l.png?ID=5730b24c-062a-4b2e-a53b-55028cf91f0f"
              alt="DMCA.com Protection Status"
            />
          </a>
        </span>
      </p>
    </div>
  </div>
);

export default FooterBottom;
